import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment as env } from "../../environments/environment";
import { Router } from "@angular/router";

export class TokenObject {
    status: string;
    token: string;
}

@Injectable()
export class AuthService {
    constructor(
        private http: HttpClient,
        private router: Router) { }

    public isAuthenticated(): boolean {
        if (localStorage.getItem('token')) {
            return true;
        }

        return false;
    }

    public login(username: string, password: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        const params = new HttpParams()
            .set('username', username)
            .set('password', password);

        const options = {
            headers,
            params
        };

        this.http.post(env.backendUrl + '/auth.php?login', null, options)
            .subscribe(
                (data: TokenObject) => {
                    if (data.status == "success") {
                        localStorage.setItem('token', data.token);

                        this.router.navigate(['/cheques/list']);
                    } else {
                        alert('Введённые логин или пароль не верны.');
                    }
                }
            )
    }
}