import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuardService as AuthGuard } from "./auth/auth-guard.service";

export const AppRoutes: Routes = [
    {
      path: '',
      redirectTo: 'cheques/list',
      pathMatch: 'full',
    }, {
      path: '',
      component: AdminLayoutComponent,
      canActivate: [AuthGuard],
      children: [
        {
            path: 'cheques',
            loadChildren: './cheques/cheques.module#ChequesModule'
        },
        {
            path: 'cashboxes',
            loadChildren: './cashboxes/cashboxes.module#CashboxesModule'
        },
        {
            path: 'users',
            loadChildren: './users/users.module#UsersModule'
        }
      ]}, {
    path: '',
      component: AuthLayoutComponent,
      children: [{
        path: 'pages',
        loadChildren: './pages/pages.module#PagesModule'
      }]
    }
];
