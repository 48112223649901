import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'cashbox-response-dialog',
    templateUrl: 'cashbox-response-dialog.component.html'
})
export class CashboxResponseDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<CashboxResponseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data) { }
}
